(function ($, generic) {
  /**
   * Event listener to display the waitlist overlay.
   * Event trigger comes from module product_waitlist_v1.
   */
  $(document).on('product.waitlist.overlay', function (event, data) {
    Drupal.behaviors.productWaitlistOverlayV1.launch(data);
  });

  Drupal.behaviors.productWaitlistOverlayV1 = {
    /**
     * Drupal behavior method to handle the waitlist overlay display and functionality.
     * After the 'product.waitlist.overlay' event is fired, the product's data is passed to the launch method.
     * The product_waitlist_overlay inline template is then rendered and displayed using generic.overlay.launch.
     * Once rendered, the waitlist form submit rpc is initialized.
     * Form submission either results in a success message or errors displaying inline.
     * All messaging copy is handled within the waitlist module in the CMS.
     */
    launch: function (data) {
      var that = this;
      var rendered = site.template.get({
        name: 'product_waitlist_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        height: 'auto',
        cssClass: 'js-waitlist-overlay-wrapper waitlist-overlay-wrapper--space',
        cssStyle: {
          // Custom styles here.
        },
        onComplete: function (response) {
          var $colorboxContanier = $('.js-waitlist-overlay-wrapper');
          var $overlayCont = $('.js-waitlist-container');
          var $formCont = $('.js-waitlist-form', $overlayCont);
          var $submit = $('.js-waitlist-submit', $overlayCont);
          var $email = $('.js-waitlist-email', $overlayCont);
          var $requestType = $('.js-waitlist-request-type', $overlayCont);
          var $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
          var $successCont = $('.js-waitlist-success');
          var $errorList = $('.js-error-messages', $overlayCont);
          // Set request type.
          var requestType = data.INVENTORY_STATUS == that.statusIds.inventory.comingSoon ? 'CS' : 'BIS';

          $requestType.val(requestType);
          // Set sku_base_id in.
          $skuBaseId.val(data.SKU_BASE_ID);
          $.colorbox.resize();
          setTimeout(function () {
            $colorboxContanier.addClass('waitlist-overlay-wrapper');
          }, 100);
          $formCont.on('submit', function (e) {
            e.preventDefault();

            var $this = $(this);
            var pObj = {};
            var params = $this.serializeArray();

            for (var i = 0, len = params.length; i < len; i++) {
              pObj[params[i].name] = params[i].value;
            }

            // Include Email promotion params
            if ($this.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
              pObj['PC_EMAIL_PROMOTIONS'] = 1;
              pObj['PC_EMAIL_PROMOTIONS_PRESENT'] = 1;
            }
            generic.jsonrpc.fetch({
              method: 'form.get',
              params: [pObj],
              onSuccess: function (r) {
                $formCont.hide();
                $successCont.show();
              },
              onFailure: function (r) {
                var errorObj = r.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
              }
            });
          });
        },
        onClosed: function () {
          var $colorboxContanier = $('.js-waitlist-overlay-wrapper');

          $colorboxContanier.removeClass('waitlist-overlay-wrapper');
        }
      });
    },

    /**
     * Status ids used by the waitlist overlay; adjust accordingly.
     */
    statusIds: {
      inventory: {
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };
})(jQuery, generic);
